import {
  UserStatus,
  FuelCategoryEnum,
  ProductCodeEnum,
  ShiftStatus,
  CustomerType,
  PaymentMethod,
  EmployeePosition,
} from "enums";
import { locale } from "localization/en";

export const prettifyUserStatus = (type) => {
  return (
    {
      [UserStatus.Active]: "Active",
      [UserStatus.Deactivated]: "Deactivated",
      [UserStatus.Deleted]: "Deleted",
    }[type] || ""
  );
};

export const prettifyShiftStatus = (type) => {
  return (
    {
      [ShiftStatus.Started]: locale.started,
      [ShiftStatus.Ended]: locale.ended,
      [ShiftStatus.Closed]: locale.closed,
    }[type] || ""
  );
};

export const prettifyCustomerType = (type) => {
  return (
    {
      [CustomerType.Transient]: locale.transient,
      [CustomerType.KeyAccount]: locale.keyAccount,
    }[type] || ""
  );
};

export const prettifyPaymentMethod = (type) => {
  return (
    {
      [PaymentMethod.Card]: "Card",
      [PaymentMethod.Cash]: "Cash",
      [PaymentMethod.Check]: "Check",
      [PaymentMethod.SplitPayment]: "Split Payment",
      [PaymentMethod.KeyAccount]: "Key Account",
      [PaymentMethod.ShopeePay]: "ShopeePay",
      [PaymentMethod.PLC]: "PLC",
      [PaymentMethod.PLB]: "PLB",
      [PaymentMethod.LOCQPay]: "LOCQPay",
      [PaymentMethod.Other]: "Other",
      [PaymentMethod.Maya]: "Maya",
      [PaymentMethod.GiftCard]: "Gift Card",
      [PaymentMethod.VIP]: "VIP Redemption",
      [PaymentMethod.GCash]: "GCash",
      [PaymentMethod.PLCLiter]: "PLC Liter",
      [PaymentMethod.PLCLocqpay]: "PLC LOCQPay for Fuel",
      [PaymentMethod.PLBFuelCode]: "PLB Fuel Code",
      [PaymentMethod.PLBFleetCard]: "PLB Fleet Card",
      [PaymentMethod.PLBManualPO]: "PLB Fleet Card (Manual PO)",
      [PaymentMethod.Cheque]: "Cheque",
      [PaymentMethod.QRPH]: "QRPH",
      [PaymentMethod.DriveOff]: "Drive Off",
      [PaymentMethod.Misload]: "Misload",
      [PaymentMethod.PLCLubes]: "PLC LOCQPay for Lubes",
      [PaymentMethod.PLBLubes]: "PLB LOCQPay for Lubes",
    }[type] || "Others"
  );
};

export const prettifyFuelCategory = (type) => {
  return (
    {
      [FuelCategoryEnum.GASOLINE]: "Gasoline",
      [FuelCategoryEnum.DIESEL]: "Diesel",
      [FuelCategoryEnum.KEROSENE]: "Kerosene",
      [FuelCategoryEnum.LPG]: "LPG",
    }[type] || ""
  );
};

export const prettifyProductCode = (type) => {
  return (
    {
      [ProductCodeEnum.DIESEL]: "Diesel",
      [ProductCodeEnum.GAS91]: "Gas 91",
      [ProductCodeEnum.GAS95]: "Gas 95",
      [ProductCodeEnum.GAS97]: "Gas 97",
      [ProductCodeEnum.LUBRICANTS]: "Lubricants",
      [ProductCodeEnum.LPG]: "LPG",
    }[type] || ""
  );
};

export const prettifyEmployeePosition = (position) => {
  return (
    {
      [EmployeePosition.STATION_HEAD]: "Station Head",
      [EmployeePosition.STATION_CREW]: "Station Crew",
      [EmployeePosition.CASHIER]: "Cashier",
      [EmployeePosition.SECURITY_GUARD]: "Security Guard",
      [EmployeePosition.SERVICE_ADVISOR]: "Service Advisor",
      [EmployeePosition.TECHNICIAN]: "Technician",
      [EmployeePosition.CREW]: "Employee",
    }[position] || ""
  );
};
