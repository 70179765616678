import React, { Fragment, useCallback, useContext, useState } from "react";
import { useApi, useMount } from "hooks";
import { AppContext, FeatureFlagContext, UserContext, StationContext } from "contexts";
import { Route, Switch } from "react-router-dom";
import { Path } from "enums";
import AppLoader from "components/commons/app-loader/app-loader";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Header from "components/commons/header/header";
import Sidebar from "components/commons/sidebar/sidebar";
import { getUserV2 } from "apis/profile.api";
import { getStationById } from "apis/station.api";
import ShiftRecordsPage from "./shift-records-page";
import BirReportPage from "./bir-report.page";
import VoidReportPage from "./void-report.page";

const AuthorizedPage = () => {
  const { appState } = useContext(AppContext);
  const { setUserEmail } = useContext(FeatureFlagContext);
  const [user, setUser] = useState();
  const [station, setStation] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { request: getUserRequest, loading: fetchingUser } = useApi({
    api: getUserV2,
    pageError: true,
  });

  const { request: getStationRequest, loading: fetchingStation } = useApi({
    api: getStationById,
    pageError: true,
  });

  useMount(() => {
    fetchUser();
    fetchStation();
  });

  const fetchUser = useCallback(async () => {
    const res = await getUserRequest();
    setUser(res);
    setUserEmail(res.email);
  }, [getUserRequest, setUserEmail]);

  const fetchStation = useCallback(async () => {
    const station = await getStationRequest({ stationId: localStorage.getItem("stationId") });
    setStation(station);
  }, [getStationRequest]);

  const loading = fetchingStation || fetchingUser || appState?.loading;
  return (
    <UserContext.Provider value={{ user, fetchUser, loading: fetchingUser }}>
      <StationContext.Provider value={{ station, fetchStation, loading: fetchingStation }}>
        {loading ? (
          <AppLoader />
        ) : user && station ? (
          <Fragment>
            <Header onClick={() => setIsDrawerOpen(!isDrawerOpen)} />
            <Sidebar />

            <div
              style={{
                marginLeft: "196px",
                marginTop: "60px",
                padding: "27px 36px",
                minWidth: "1170px",
                overflowX: "auto",
                minHeight: "100vh",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  overflowX: "auto",
                  padding: "27px 36px",
                }}
              >
                <Switch>
                  <Route path={Path.ShiftRecords} component={ShiftRecordsPage} />
                  <Route path={Path.BirReport} component={BirReportPage} />
                  <Route path={Path.VoidTransaction} component={VoidReportPage} />
                  <Redirect to={Path.ShiftRecords} />
                </Switch>
              </div>
            </div>
          </Fragment>
        ) : null}
      </StationContext.Provider>
    </UserContext.Provider>
  );
};

export default AuthorizedPage;
