import { Text } from "components/commons";
import { dateFormat, formatAmount, formatVolume, timeFormat } from "utils";

export const mapDataToList = ({ transactions }) => {
  const { processedAt, receiptNumber, totalAmount, refName, items } = transactions;

  const item = items?.[0] || {};

  return {
    date: (
      <>
        <Text>{dateFormat(processedAt ?? "")}</Text>
        <Text>{timeFormat(processedAt ?? "", true)}</Text>
      </>
    ),
    salesInvoiceNo: <Text>{receiptNumber ?? "--"}</Text>,
    transactionNo: <Text>{item?.transactionItemId || "--"}</Text>,
    cashier: <Text>{refName || "--"}</Text>,
    description: <Text>{item?.name || "--"}</Text>,
    sku: <Text>{item?.sku || "--"}</Text>,
    qty: (
      <Text>
        {item?.type === "fuel" ? formatVolume(item?.quantity ?? 0) : item?.quantity || "--"}
      </Text>
    ),
    unitPrice: <Text>{formatAmount(item?.originalPricePerItem || 0)}</Text>,
    discount: <Text>{item?.discount || "--"}</Text>,
    refund: <Text>{formatAmount(totalAmount ?? 0)}</Text>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;

  return {
    ...fs,
  };
};
