import { Path } from "enums";
import VoidTransactionModule from "modules/bir-report/void-transaction/void-transaction.module";
import { Route, Switch, Redirect } from "react-router";

const VoidReportPage = () => {
  return (
    <Switch>
      <Route exact path={Path.VoidTransaction} component={VoidTransactionModule} />
      <Redirect to={Path.VoidTransaction} />
    </Switch>
  );
};

export default VoidReportPage;
