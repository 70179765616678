import React from "react";
import styles from "./void-transaction-filter.module.scss";
import { Field, Filter, DateRange, Button } from "components/commons";
import { locale } from "localization/en";
import { SearchField } from "components/commons";

const VoidTransactionFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  resetFilter,
  exportReport,
  exporting,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={() => {
            applyFilter();
          }}
          filterCount={filterCount}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale.date}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({ startDate, endDate });
              }}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={[locale.productDescription, locale.transactionNo].join(", ")}
            value={searchKey}
            searchWithSymbols={false}
            restrict={false}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            disabled={exporting}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default VoidTransactionFilter;
