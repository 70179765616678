import locale from "localization";

export const voidTransactionColumn = [
  { key: "date", text: locale.dateAndTime },
  { key: "transactionNo", text: locale.transactionNo },
  { key: "cashier", text: locale.cashier, width: "13%" },
  { key: "description", text: locale.description, width: "13%" },
  { key: "sku", text: locale.sku },
  { key: "qty", text: locale.qty },
  { key: "amount", text: locale.amount },
  { key: "reason", text: locale.reason },
];
