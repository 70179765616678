import locale from "localization";

export const refundTransactionColumn = [
  { key: "date", text: locale.dateAndTime, width: "15%" },
  { key: "salesInvoiceNo", text: locale.referenceSalesInvoiceNo },
  { key: "transactionNo", text: locale.transactionNo },
  { key: "cashier", text: locale.cashier, width: "10%" },
  { key: "description", text: locale.description, width: "10%" },
  { key: "sku", text: locale.sku, width: "10%" },
  { key: "qty", text: locale.qty },
  { key: "unitPrice", text: locale.unitPrice, width: "10%" },
  { key: "discount", text: locale.discount },
  { key: "refund", text: locale.refund },
];
