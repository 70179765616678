const AccessAuthorization = "/access-authorization";
const ShiftRecords = "/shift-records";
const BirReport = "/bir-report";
const Path = {
  Slash: "/",
  Auth: "/auth",
  AccessAuthorization: AccessAuthorization,
  UserAccess: `${AccessAuthorization}/user-access`,
  ShiftRecords: ShiftRecords,
  ShiftDetails: `${ShiftRecords}/details`,
  ViewShiftDetails: `${ShiftRecords}/details/:id/:pageMode`,
  ViewShiftDetailsInfo: `${ShiftRecords}/details/:id/info/:pageMode`,
  ViewShiftDetailsSalesInventory: `${ShiftRecords}/details/:id/sales-inventory/:pageMode`,
  ViewShiftDetailsCashNonCash: `${ShiftRecords}/details/:id/cash/:pageMode`,
  ViewShiftDetailsInfoById: (id, pageMode) => `${ShiftRecords}/details/${id}/${pageMode}`,
  ViewShiftDetailsSalesInventoryById: (id, pageMode) =>
    `${ShiftRecords}/details/${id}/sales-inventory/${pageMode}`,
  ViewShiftDetailsCashNonCashById: (id, pageMode) =>
    `${ShiftRecords}/details/${id}/cash/${pageMode}`,
  BirReport: BirReport,
  SalesSummary: `${BirReport}/sales-summary`,
  DiscountReport: `${BirReport}/discount-report`,
  SalesTransaction: `${BirReport}/sales-transaction`,
  RefundTransaction: `${BirReport}/refund-transaction`,
  VoidTransaction: `/void-transaction`,
};

export default Path;
