import { Intro } from "components/commons";
import locale from "localization";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import { mapDataToList, mapFilterToRequest } from "./void-transaction.mapper";
import useExport from "hooks/useExport";
import { generateReport } from "apis/generate-report.api";
import styles from "./void-transaction.module.scss";
import initialFilterState from "./void-transaction-filter.state";
import { getTransactions } from "apis/transactions";
import { StationContext } from "contexts";
import { useContext } from "react";
import VoidTransactionFilter from "./void-transaction-filter";
import { voidTransactionColumn } from "./void-transation-column";
import { TransactionStatusEnum } from "enums";

const VoidTransactionModule = () => {
  const { station } = useContext(StationContext);

  const STATUS = TransactionStatusEnum.VOID;

  const { filter, search, table } = useDataTable({
    api: {
      api: getTransactions,
      params: {
        page: 1,
        perPage: 10,
        stationCode: station.stationCode,
        status: STATUS,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "transactions",
      mapper: (transactions) => mapDataToList({ transactions }),
      columns: voidTransactionColumn,
    },
  });

  const exports = useExport({
    api: generateReport,
    hasModal: false,
    reportType: "transaction",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search?.searchKey,
      stationCode: station.stationCode,
      status: STATUS,
    },
  });

  return (
    <>
      <Intro title={locale.voidReport} />
      <VoidTransactionFilter {...filter} {...search} {...exports} />
      <div className={styles.table}>
        <DataTableV2 {...table} />
      </div>
    </>
  );
};

export default VoidTransactionModule;
