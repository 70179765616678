const PaymentMethod = {
  Card: "card",
  Cash: "cash",
  GCash: "gcash",
  LOCQPay: "locqpay",
  SplitPayment: "split-payment",
  Maya: "maya",
  KeyAccount: "key-account",
  ShopeePay: "shopeepay",
  PLC: "plc",
  PLB: "plb",
  Other: "other",
  GiftCard: "gift-card",
  Check: "check",
  VIP: "vip-redemption",
  DriveOff: "drive-off",
  Cheque: "cheque",
  Misload: "misload",
  QRPH: "qrph",
  PLBManualPO: "plb-fleet-card-manual",
  PLBFleetCard: "plb-fleet-card",
  PLCLocqpay: "plc-locqpay-fuel",
  PLCLiter: "plc-liter",
  PLBFuelCode: "plb-fuel-code",
  PLCLubes: "plc-locqpay-lubes",
  PLBLubes: "plb-locqpay-lubes",
};

export default PaymentMethod;
