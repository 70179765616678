import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { Path } from "enums";
import SalesSummaryModule from "modules/bir-report/sales-summary/sales-summary.module";
import DiscountReportModule from "modules/bir-report/discount-report/discount-report.module";
import SalesTransactionModule from "modules/bir-report/sales-transaction/sales-transaction.module";
import RefundTransactionModule from "modules/bir-report/refund-transaction/refund-transaction.module";

const BirReportPage = () => {
  return (
    <Switch>
      <Route exact path={Path.SalesSummary} component={SalesSummaryModule} />
      <Route exact path={Path.DiscountReport} component={DiscountReportModule} />
      <Route exact path={Path.SalesTransaction} component={SalesTransactionModule} />
      <Route exact path={Path.RefundTransaction} component={RefundTransactionModule} />
      <Redirect to={Path.SalesSummary} />
    </Switch>
  );
};

export default BirReportPage;
